<template>
  <hb-modal
    size="large"
    title="Create/Edit Promotion Plan"
    v-model="dialog"
    @close="closeForm"
  >
    <template v-slot:content>
      <hb-stepper v-model="step">
        <hb-stepper-header :force-resize="resizeStepper">
          <template v-for="(item, index) in stepData">
            <hb-stepper-step
              :key="'step-' + index"
              @click="goToStep(index + 1)"
              :step="index + 1"
              :complete="item.status === 'complete'"
              :rules="[() => item.status !== 'error']"
            >
              {{ item.name }}
            </hb-stepper-step>

            <v-divider
              :key="'line-' + index"
              v-if="index + 1 < stepData.length"
            ></v-divider>
          </template>
        </hb-stepper-header>
        <div class="hb-text-light pa-4" v-html="description"></div>
        <v-divider></v-divider>
        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <CreatePlanDetails :step-data="planData" ref="step1" />
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0">
            <OccupancyBasedPromotion :step-data="planData" ref="step2" />
          </v-stepper-content>
          <v-stepper-content step="3" class="pa-0">
            <CreatePlanOverrides :step-data="planData" ref="step3" />
          </v-stepper-content>
          <v-stepper-content step="4" class="pa-0">
            <CreatePlanTierOverrides :step-data="planData" ref="step4" />
          </v-stepper-content>
        </v-stepper-items>
      </hb-stepper>
    </template>
    <template v-slot:right-actions>
      <hb-btn
        v-if="step > 1 && step != 4"
        @click="submit()"
        :color="'secondary'"
        :loading="skipLoader"
      >
        Save
      </hb-btn>
      <hb-btn
        @click="step > 3 ? submit(true) : goToStep(step + 1)"
        :color="'primary'"
        :loading="loading"
      >
        {{ step > 3 ? "Save" : "Next" }}
      </hb-btn>
    </template>
  </hb-modal>
</template>
<script>
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { cloneDeep } from "lodash";

import OccupancyBasedPromotion from "./OccupancyBasedPromotion.vue";
import CreatePlanOverrides from "./CreatePlanOverrides.vue";
import CreatePlanTierOverrides from "./CreatePlanTierOverrides.vue";
import CreatePlanDetails from "./CreatePlanDetails.vue";

import api from "../../../assets/api";

export default {
  name: "CreatePromotionPlan",
  components: {
    OccupancyBasedPromotion,
    CreatePlanOverrides,
    CreatePlanTierOverrides,
    CreatePlanDetails,
  },
  mixins: [notificationMixin],
  props: {},
  data() {
    return {
      dialog: false,
      loading: false,
      skipLoader: false,
      resizeStepper: 0,
      step: 1,
      planData: {},
      stepData: [
        {
          name: "Details*",
          description:
            "Provide a name and description for your promotion plan.",
          status: "",
        },
        {
          name: "Plan Default*",
          description:
            "Add occupancy thresholds to establish the promotion plan default values and their associated promotions. The promotion that this plan generates will be the default in no overrides apply.",
          status: "",
        },
        {
          name: "Overrides",
          description:
            "Add and manage override events that take effect over the promo plan defaults. Arrange the overrides by priority from top to bottom.",
          status: "",
        },
        {
          name: "Value Tier Overrides",
          description:
            "Based on amenities, a space can be offered as one of three  value tiers within a space group (Good/Better/Best). To differentiate the value tiers even more we offer three options to choose the promotion strategy for a specific value tier.<br><br>Option 1: No Promotion<br>Option 2: Use the Plan Promotion generated by the Plan Promotion Rules<br>Option 3: Choose a specific Promotion out of the Promotion Library",
          status: "",
        },
      ],
    };
  },
  computed: {
    description() {
      return this.stepData[this.step - 1].description ?? "";
    },
  },
  methods: {
    async show(data = {}) {
      this.id = data?.id;
      this.planData = {
        ...data,
        overrides: data.overrides
          ? cloneDeep(data.overrides.sort((a, b) => a.sort - b.sort))
          : [],
      };
      this.dialog = true;
    },

    closeForm() {
      this.$emit("close");
      this.planData = {};
      this.step = 1;

      this.stepData.forEach((item) => {
        item.status = "";
      });
      this.resetData();
    },

    resetData() {
      for (let i = 1; i <= 4; i++) {
        let refObj = this.$refs?.[`step${i}`];
        if (refObj) refObj?.resetData();
      }
    },

    async goToStep(step) {
      if (step >= this.step && step === this.step + 1) {
        const stepRef = this.$refs?.[`step${this.step}`];
        if (!stepRef) return;

        const stepData = await stepRef.submit();
        if (!stepData) {
          if (this.planData && Object.keys(this.planData).length) {
            this.step = step;
          }
          return;
        }

        if (stepData.status) {
          this.stepData[this.step - 1].status = "complete";
          this.planData = { ...this.planData, ...stepData.validatedData };
          this.step = step;
        } else {
          this.stepData[this.step - 1].status = "error";
        }
      } else if (step <= this.step) {
        this.$refs?.[`step${this.step}`].$validator.errors.clear();
        this.step = step;
      }
    },
    updateNoPromotion(value, type = "remove") {
      return value.map((item) => {
        if (type == "remove")
          if (item.promotion_id == "no-promotion") item.promotion_id = null;
          else if (item.promotion_id == null)
            item.promotion_id = "no-promotion";
        return item;
      });
    },

    async submit(finalSave = false) {
      if (finalSave) this.loading = true;
      else this.skipLoader = true;
      let stepData = await this.$refs?.[`step${this.step}`]?.submit();
      if (stepData.status) {
        let data = {
          ...this.planData,
          ...stepData.validatedData,
        };
        data.plan_default = this.updateNoPromotion(data.plan_default);
        delete data["isDefault"];
        try {
          const apiMethod = this.id ? "put" : "post";
          const url = api.PROMOTIONS + `plans${this.id ? `/${this.id}` : ""}`;
          const successMsg = this.id ? `updated` : `created`;
          await api[apiMethod](this, url, data);
          this.showMessageNotification({
            type: "success",
            description: `Promotion Plan '${data.name}' successfully ${successMsg}`,
          });
          this.$emit("success");
          this.dialog = false;
          this.closeForm();
        } catch (error) {
          const errorMsg = this.id ? `updation` : `creation`;
          this.showMessageNotification({
            type: "error",
            description: `Promotion Plan ${errorMsg} failed.`,
          });
        }
      }
      this.skipLoader = false;
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
