<template>
  <div class="promo-conditions">
    <p class="hb-text-light mb-0">Requirements</p>
    <div v-for="(item, key) in form" :key="key" class="promo-condition-item">
      <p v-if="key === 'remove_on_delinquency'" class="hb-text-light mb-2">
        Restrictions
      </p>
      <template v-if="key === 'tenants'">
        <hb-checkbox v-model="checkedItems[key]">
          <template v-slot:label>
            <hb-select
              :items="item.options"
              v-model="item.value"
              :key="item.value"
              item-value="value"
              item-text="label"
              @click.stop.prevent="setValue()"
              placeholder="Select Type"
              :disabled="!checkedItems[key]"
              :clearable="false"
              medium
            >
            </hb-select>
            Tenants
          </template>
        </hb-checkbox>
      </template>
      <template
        v-else-if="['length_of_stay', 'months_to_prepay'].includes(key)"
      >
        <hb-checkbox v-model="checkedItems[key]">
          <template v-slot:content>
            <v-row no-gutters>
              <div class="hb-inline-sentence-text">
                {{ item.label }}
              </div>
              <hb-text-field
                v-model="item.value"
                maxlength="10"
                placeholder="0"
                small
                box
                condensed
                :disabled="!checkedItems[key]"
              >
              </hb-text-field>
            </v-row>
          </template>
        </hb-checkbox>
      </template>
      <template v-else>
        <hb-checkbox v-model="item.value">
          <template v-slot:label>{{ item.label }}</template>
          <template v-if="item.tooltip" v-slot:tooltipHeader>
            {{ item.tooltipHeader }}
          </template>
          <template v-if="item.tooltip" v-slot:tooltipBody>
            {{ item.tooltipBody }}
          </template>
          <template v-slot:description>{{ item.description }}</template>
        </hb-checkbox>
      </template>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "PromoConditions",
  props: {
    conditions: {
      type: Array,
      default: () => [],
    },
    lengthOfStay: {
      type: Number,
      default: 0,
    },
    monthToPrepay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      checkedItems: {
        tenants: false,
        length_of_stay: false,
        months_to_prepay: false,
      },
      defaultConditions: {
        tenants: {
          options: [
            {
              label: "New",
              value: "new_tenants",
            },
            {
              label: "Existing",
              value: "existing_tenants",
            },
          ],
          value: "all_tenants",
        },
        // length_of_stay: {
        //   label: "Required Length of Stay",
        //   value: 0,
        // },
        months_to_prepay: {
          label: "Required Number of Months(Prepay)",
          value: 0,
        },
        // web_only: {
        //   label: "Web Only",
        //   value: false,
        // },
        require_autopay: {
          label: "Require Autopay",
          value: false,
        },
        // require_coverage: {
        //   label: "Require Protection Plan Enrollment",
        //   value: false,
        // },
        // require_id_verification: {
        //   label: "Require ID Verification",
        //   value: false,
        // },
        remove_on_delinquency: {
          label:
            "Make Promotion eligible to be removed when a Tenant is delinquent.",
          tooltip: false,
          description:
            "*This condition requires a Delinquency Timeline trigger.",
          value: false,
        },
      },
      form: {},
    };
  },
  computed: {
    isEmptyConditionsData() {
      return this.conditions.length === 0;
    },
  },
  watch: {
    conditions: {
      immediate: true,
      handler(data) {
        this.form = cloneDeep(this.defaultConditions);
        this.checkedItems = {
          tenants: false,
          length_of_stay: false,
          months_to_prepay: false,
        };
        if (data?.length)
          Object.keys(this.form).forEach((key) => {
            let value = false;
            if (key == "tenants") {
              value =
                ["all_tenants", "new_tenants", "existing_tenants"].find((val) =>
                  this.conditions.includes(val)
                ) ?? "all_tenants";
              if (value != "all_tenants") this.checkedItems[key] = true;
            } else if (key == "length_of_stay") {
              value = this.lengthOfStay;
              if (this.lengthOfStay) this.checkedItems[key] = true;
            } else if (key == "months_to_prepay") {
              value = this.monthToPrepay;
              if (this.monthToPrepay) this.checkedItems[key] = true;
            } else if (this.conditions.includes(key)) value = true;
            this.form[key].value = value;
          });
      },
    },
  },
  methods: {
        setValue() {
      console.log("=-=-=-=-==-=--=-=-=-=-=-=-setValue=-=-==-=-=-=-=-=-=-=-=-");
    },
    reset() {
      this.form = cloneDeep(this.defaultConditions);
      this.checkedItems = {
        tenants: false,
        length_of_stay: false,
        months_to_prepay: false,
      }
    },
    getAPIData() {
      let data = this.form;
      let conditions = [];
      let lengthOfStay = null;
      let monthToPrepay = null;
      Object.keys(data).forEach((key) => {
        let value = data[key].value;
        switch (key) {
          case "length_of_stay":
            lengthOfStay = this.checkedItems["length_of_stay"] ? value : null;
            break;
          case "months_to_prepay":
            monthToPrepay = this.checkedItems["months_to_prepay"]
              ? value
              : null;
            break;
          case "tenants":
            if (this.checkedItems['tenants'])
              if (["new_tenants", "existing_tenants"].includes(value))
                conditions.push(value);
            break;
          default:
            if (value) conditions.push(key);
        }
      });
      return {
        conditions,
        lengthOfStay,
        monthToPrepay,
      };
    },
  },
};
</script>

<style lang="scss">
.promo-conditions {
  .v-messages {
    min-height: 10px;
  }
  .promo-condition-item {
    min-height: 48px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
</style>